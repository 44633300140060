<template>
  <div class="div-main-list-page">
    <div class="div-main-list-page-title">
      <h1>Posted List</h1>
    </div>
    <el-row  class="top-tab-botton-bar" :gutter="10" >
      <el-col :xs="24" :sm="8" :md="10" >
        <el-tabs  style="width:150px"  v-model="postedListTabActive" @tab-click="getPostedList(postedListPagination.pageSize, 0)">
          <el-tab-pane label="Pending" name="VERIFIED"/>
          <el-tab-pane label="Approved" name="READY_FOR_INVOICING"/>
        </el-tabs>
      </el-col>
      <el-col :xs="24" :sm="16" :md="14" class="flex-end" style="white-space: nowrap" >
          <el-input
            v-model="searchPostedList" class="round-input search-bar" placeholder="Search Vessel/Terminal"
            @keypress.enter.native="getPostedList(postedListPagination.pageSize, 0)"
          >
            <el-button @click="getPostedList(postedListPagination.pageSize, 0)" type="primary" slot="append" icon="el-icon-search"/>
          </el-input>
          <span  style="padding-top:5px; white-space: nowrap">
            <el-button
              v-if="postedListTabActive === 'VERIFIED' && hasPermission('authorized_signatory_approval')"
              round  outline type="success"
              @click="postedListApproval(true)"
            >
              Approve
            </el-button>
            <el-button
              v-if="(postedListTabActive === 'VERIFIED' && hasPermission('authorized_signatory_approval')) ||
              (postedListTabActive === 'READY_FOR_INVOICING' && hasPermission('accounts_approval'))"
              round  type="danger"
              @click="postedListApproval(false)"
            >
              Reject
            </el-button>
            <el-button
              v-if="postedListTabActive === 'READY_FOR_INVOICING' && hasPermission('accounts_approval')"
              round type="primary"
              @click="createInvoice"
            >
              Create Invoice
            </el-button>
          </span>
      </el-col>
    </el-row>
    <div class="div-main-list-page-table">
      <el-table
        :data="postedListTableData"
        height="100%"
        row-key='id'
        :expand-row-keys="expandedRowsKey"
        @expand-change="handlePostedListExpandChange"
        :border="true"
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="CELL_STYLE"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.orderItems" :cell-style="CELL_STYLE" :header-cell-style="{  alignSelf: 'center', fontSize: '12px'
            }" @selection-change="handlePostedListSelectChange">
              <el-table-column type="selection" min-width="40" v-if="(postedListTabActive === 'VERIFIED' && hasPermission('authorized_signatory_approval')) ||
              (postedListTabActive === 'READY_FOR_INVOICING' && hasPermission('accounts_approval'))"/>
              <el-table-column label="Item" min-width="250px" show-overflow-tooltip>
                <template v-slot="scope">
                  <p>{{ scope.row.itemType === 'PRODUCT' ? scope.row.sysProductName : scope.row.description }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Quantity" min-width="150px" show-overflow-tooltip>
                <template v-slot="scope">
                  <p>{{ scope.row.itemType === 'PRODUCT' && scope.row.verifiedQuantity ? numberWithCommas(scope.row.verifiedQuantity, 3) : numberWithCommas(scope.row.quantity, 3) }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Currency" min-width="150px">
                <template v-slot="scope">
                  <p>{{ scope.row.currency }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Price" min-width="150px" show-overflow-tooltip>
                <template v-slot="scope">
                  <p>{{ scope.row.itemType === 'PRODUCT' && scope.row.finalBillingPrice ? numberWithCommas(scope.row.finalBillingPrice, 3) : numberWithCommas(scope.row.unitPrice, 3) }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Amount" min-width="150px" show-overflow-tooltip>
                <template v-slot="scope">
                  <p v-if="scope.row.itemType === 'PRODUCT'">{{ scope.row.verifiedQuantity && scope.row.finalBillingPrice ? numberWithCommas(scope.row.verifiedQuantity * scope.row.finalBillingPrice, 3) : '-' }}</p>
                  <p v-else>{{ numberWithCommas(scope.row.cost, 3) }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Date Delivered" min-width="150px">
                <template v-slot="scope">
                  <p>{{ (scope.row.itemType === 'PRODUCT' || scope.row.itemType === 'OTHER_COST') && scope.row.supplyDate ? $moment(scope.row.supplyDate).format('DD-MM-YYYY') : '-' }}</p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
          <el-table-column label="Order No." prop="salesOrderNo" min-width="150px" show-overflow-tooltip/>
          <el-table-column label="Vessel/Terminal" min-width="150px" show-overflow-tooltip>
            <template v-slot="scope">
              <p>{{ (scope.row.orderType === 'DELIVERED' || scope.row.orderType === 'BARGING_NOMINATION') ? scope.row.vesselName : (scope.row.terminal || '-') }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Customer Company" min-width="150px" prop="buyerCompanyName" show-overflow-tooltip/>
          <el-table-column label="Payment Terms" min-width="100px">
            <template v-slot="scope">
              <p>{{ capitalizeFirstLetter(scope.row.paymentTerm, '_') }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Order Created By" min-width="150px" prop="sellerName" show-overflow-tooltip/>
        </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handlePostedListPageSizeChange"
          @current-change="handlePostedListPageChange"
          :page-size.sync="postedListPagination.pageSize"
          :page-sizes="[5, 10, 20, 50, 100]"
          :current-page="postedListPagination.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="postedListPagination.total" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { capitalizeFirstLetter, numberWithCommas } from '@/utils/index.js'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
export default {
  name: 'PostedList',
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      postedListTabActive: 'VERIFIED',
      postedListTableData: [],
      searchPostedList: '',
      orderSelected: null,
      productsSelectedForApproval: [],
      otherCostsSelectedForApproval: [],
      expandedRowsKey: [],
      postedListPagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0
      },
      isPageNumberAndSizeChange: false
    }
  },
  created () {
    this.getPostedList(this.postedListPagination.pageSize, this.postedListPagination.pageNumber)
  },
  computed: {
    ...mapState(['currentCompany'])
  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          setTimeout(() => {
            this.getPostedList(this.postedListPagination.pageSize, this.postedListPagination.pageNumber)
          }, 1000)
        }
      },
      immediate: false
    }
  },
  methods: {
    capitalizeFirstLetter,
    numberWithCommas,
    handlePostedListExpandChange (selection, expandedRows) {
      if (expandedRows.length === 0) {
        this.expandedRowsKey = []
        this.orderSelected = null
      } else if (expandedRows.length > 1) {
        this.expandedRowsKey = [selection.id]
        this.orderSelected = selection
      } else {
        this.expandedRowsKey.push(selection.id)
        this.orderSelected = selection
      }
      this.productsSelectedForApproval = []
      this.otherCostsSelectedForApproval = []
    },
    handlePostedListSelectChange (selection) {
      this.productsSelectedForApproval = selection.filter(s => s.itemType === 'PRODUCT')
      this.otherCostsSelectedForApproval = selection.filter(s => s.itemType === 'OTHER_COST')
    },
    createInvoice () {
      if (this.productsSelectedForApproval.length === 0 && this.otherCostsSelectedForApproval.length === 0) {
        return this.$message.error('Must select at least one item for invoicing')
      }

      const orderItemsSelected = [...this.productsSelectedForApproval, ...this.otherCostsSelectedForApproval]

      const currencyType = []
      orderItemsSelected.forEach(i => {
        currencyType.push(i.currency)
      })
      if ((new Set(currencyType)).size > 1) {
        return this.$message.error('Must select items with same currency for invoicing')
      }
      this.orderSelected.orderType = this.orderSelected.orderType.toUpperCase()

      this.$router.push({
        name: 'MENU_ACCOUNTS_RECEIVABLE_INVOICE',
        component: () => import('../invoice'),
        params: { data: { order: this.orderSelected, orderItems: orderItemsSelected } }
      })
    },
    getPostedList (pageSize, pageNumber) {
      const baseSearchCriteria = `sellerSysOrganizationId :'${this.currentCompany.id}' and (((termContract is not null) and termContract.termContractType ! 'TIME_CHARTER') or termContract is null) and (exists(products.status :'${this.postedListTabActive}') or exists(otherCosts.status :'${this.postedListTabActive}'))`
      const baseSortCriteria = 'updatedAt desc'
      const queryString = `pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${baseSearchCriteria}${this.searchPostedList?.trim() ? `and (vesselName ~'*${this.searchPostedList}*' or terminal ~'*${this.searchPostedList}*')` : ''}&sort=${baseSortCriteria}`

      this.$request.get({
        url: `${this.$apis.orders}?${queryString}`
      }).then(res => {
        if (res?.code === 1000) {
          this.postedListTableData = res.data.content.map(o => {
            const orderItems = []
            if (o.products) {
              o.products.filter(p => p.status === this.postedListTabActive).forEach(p => { orderItems.push({ ...p, itemType: 'PRODUCT' }) })
            }
            if (o.otherCosts) {
              o.otherCosts.filter(oc => oc.status === this.postedListTabActive).forEach(oc => orderItems.push({ ...oc, itemType: 'OTHER_COST' }))
            }
            return {
              ...o,
              orderItems
            }
          })
        }
      })
    },
    postedListApproval (isApproved) {
      if (this.productsSelectedForApproval.length === 0 && this.otherCostsSelectedForApproval.length === 0) {
        return this.$message.error('Must select at least one item for approval')
      }
      const data = {
        orderId: this.orderSelected.id,
        productIds: this.productsSelectedForApproval.map(p => p.id),
        otherCostIds: this.otherCostsSelectedForApproval.map(oc => oc.id),
        isApproved
      }

      this.$request.post({
        url: this.postedListTabActive === 'VERIFIED' ? this.$apis.postedListAuthorizedSignatoryApproval : this.$apis.postedListAccountApproval,
        data
      }).then(res => {
        if (res?.code === 1000) {
          isApproved ? this.$message.success('Order has been approved.') : this.$message.success('Order has been rejected.')
          this.refreshPostedList()
        }
      })
    },
    refreshPostedList () {
      this.productsSelectedForApproval = []
      this.otherCostsSelectedForApproval = []
      this.orderItemsSelected = null
      this.orderSelected = null
      this.expandedRowsKey = []
      this.getPostedList(this.postedListPagination.pageSize, 0)
    },
    handlePostedListPageChange (pageNumber) {
      if (!this.isPageNumberAndSizeChange) {
        this.getPostedList(this.postedListPagination.pageSize, pageNumber - 1)
      }
    },
    handlePostedListPageSizeChange (pageSize) {
      this.isPageNumberAndSizeChange = true
      this.getPostedList(pageSize, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.div-main-list-page-tab-search-btn {
  grid-template-columns: minmax(150px, auto) 20px 315px 1fr 230px 10px;
  column-gap: 10px;
}
.top-tab-botton-bar {
  margin-bottom: 8px;
}
</style>
